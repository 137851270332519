<img [src]="holiday.imagen.url" class="header-img d-block w-100">
<app-breadcrumb [menuItems]="breadcrumbItems"></app-breadcrumb>
<div class="container">

  <div *ngIf="holiday" class="row">
    <div class="col-md-12">

      <button *ngIf="pagination?.total > 0" (click)="getByHolidayTag()" type="button"
        class="btn btn-primary btn-icon btn-download">
        <i class="bx bxs-download"></i>
        Descargar catálogo
      </button>
      <h1>{{holiday.title}}</h1>
      <h2>{{holiday.description}}</h2>
    </div>
  </div>

  <div class="row">
    <div *ngFor="let result of publishments
         | paginate: {
                      id: 'searchPagination',
                      itemsPerPage: pagination.perPage,
                      currentPage: pagination.currentPage ,
                      totalItems: pagination.total
                    };
                      let i = index" class="container-card col-md-6 col-lg-4">

      <div class="card" tabindex="0" id="tab_{{ i }}">
        <img [src]="result.imagenPortada?.url" onerror="this.src='assets/images/card-image-overlay.jpg'"
          class="card-img-top" [alt]="result.titulo" />
        <div class="card-body">
          <h3 class="card-title">
            <a [routerLink]="showPublishmentDetail(result)" class="card-title-link">{{ result.titulo }}</a>
          </h3>
          <p class="card-text">{{ result.descripcion | truncateWords:15 }}</p>
        </div>
      </div>


    </div>
  </div>

  <pagination-template #p="paginationApi" id="searchPagination" (pageChange)="getPublishments($event)">
    <div class="storybook__container-pagination">
      <nav>
        <ul class="pagination">
          <li class="page-item" [class.disabled]="p.isFirstPage()" (click)="p.previous()" aria-label="Anterior">
            <a class="page-link">
              <span class="page-previous-icon" aria-hidden="true"></span>
              <span class="page-previous-text">Anterior</span>
            </a>
          </li>

          <li *ngFor="let page of p.pages" class="page-item" [class.active]="p.getCurrent() === page.value"
            (click)="p.setCurrent(page.value)">
            <a class="page-link">{{ page.label }}</a>
          </li>

          <li class="page-item" [class.disabled]="p.isLastPage()" (click)="p.next()" aria-label="Siguiente">
            <a class="page-link">
              <span class="page-next-text">Siguiente</span>
              <span class="page-next-icon" aria-hidden="true"></span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </pagination-template>

</div>
<app-help-button></app-help-button>
