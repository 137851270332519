<app-slider></app-slider>

<div class="container justify-content-center pb-5 pt-5" id="buscador-home">
  <h1 class="text-center">¿Qué estás buscando?</h1>
  <p class="lead text-center">
    Ingresá el producto o servicio que necesitas, o el nombre de un
    emprendimiento específico para ver los resultados. Asegúrate de
    <span class="bold">seleccionar una categoría de búsqueda</span> de la lista
    antes de presionar <span class="bold">buscar</span>.
  </p>
  <app-search-form></app-search-form>
</div>

<div class="justify-content-center pb-5 pt-5" id="valor-ba">

  <img class="w-100 mb-5" src="/assets/images/banner-que-es-valorba.png" alt="¿Qué es ValorBA?">


  <div class="panel-footer d-flex justify-content-center">
    <a class="btn btn-primary btn-lg mr-3" (click)="goToBuy()">Quiero comprar</a>
    <a class="btn btn-secondary btn-lg" (click)="goToSell()">Quiero vender</a>
  </div>
</div>

<div class="container-fluid justify-content-center mt-3 pt-5 pb-5 home-slider">
  <div class="container">
    <h2 class="text-center mb-5">
      ¿Qué impactos generan las compras de ValorBA?
    </h2>
    <div class="row">
      <div class="col-md-3 text-center">
        <img class="icon pb-3" src="/assets/images/impacto/impacto-social.png" alt="impacto social" />
        <p class="lead bold text-center">Impacto Social</p>
        <p class="status-text text-center">
          Mejora la economía de
          proveedores en situación de vulnerabilidad
          social y económica.
        </p>
      </div>
      <div class="col-md-3 text-center">
        <img class="icon pb-3" src="/assets/images/impacto/impacto-genero.png" alt="impacto de género" />
        <p class="lead bold text-center">Impacto de Género</p>
        <p class="status-text text-center">
          Crea oportunidades a
          proveedores integrados mayoritariamente por
          mujeres y disidencias.
        </p>
      </div>
      <div class="col-md-3 text-center">
        <img class="icon pb-3" src="/assets/images/impacto/impacto-ambiental.png" alt="impacto ambiental" />
        <p class="lead bold text-center">Impacto Ambiental</p>
        <p class="status-text text-center">
          Colabora con proveedores que
          promueven el cuidado del planeta y el fomento a la economía circular.
        </p>
      </div>
      <div class="col-md-3 text-center">
        <img class="icon pb-3" src="/assets/images/impacto/impacto-economico.png" alt="impacto económico" />
        <p class="lead bold text-center">Impacto Económico</p>
        <p class="status-text text-center">
          Impulsa la economía de
          pequeños y medianos proveedores para que
          sigan creciendo.
        </p>
      </div>
    </div>
  </div>
</div>
<app-help-button></app-help-button>